import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttHeading,
    SttPaper,
    SttBox,
    SttLoading,
    SttContainer,
    SttExpansionPanel,
    SttTextItem,
    SttDivider,
    SttAlerta,
    SttNotification,
    SttGrid,
    SttLink
} from '@stt-componentes/core';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import translate from '../../translate';
import { Util } from '@stt-utilitarios/core';
import Instituicao from './instituicao';
import Modalidade from './modalidade';
import Setor from './setor';
import Equipe from './equipe-saude'
import Form from './form';
import { SvgIcon } from '@material-ui/core';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../azure-ad/authConfig";
import UtilLocal from "../../util";
import axios from 'axios';
import httpStatus from 'http-status-codes';
import { Cache } from '@stt-componentes/cache';
import { setUser as setUserAction } from '../../reducers/actions';
import { ORIGEM_AUTENTICACAO_EXTERNA } from '../../common/AppConstants';
import util from '../../util';
const { ServerError, ClientAuthError, BrowserAuthError } = require('@azure/msal-browser');

const useStyles = makeStyles((theme) => ({
    heading: {
        marginTop: '20px',
        marginBottom: '30px'
    },
    gridItem: {
        display: 'flex',
        alignContent: 'center'
    },
    notificacao: {
        marginBottom: theme.spacing(1.5),
    },
    grid: {
        marginBottom: theme.spacing(5)
    },
    formColumn: {
        backgroundColor: theme.palette.background.default,
        border: '2px solid',
        borderColor: '#D9D9D9',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        width: '80%',
        marginTop: theme.spacing(5)
    },
    expansionPanel: {
        width: '100%'
    },
    box: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    alert: {
        margin: theme.spacing(2)
    },
    alertTitle: {
        marginBottom: 0
    },
    iconeAutenticacaoExterna: {
        fontSize: theme.spacing(7)
    },
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const MeusDados = ({ user, strings, setUser }) => {
    // Classes css
    const classes = useStyles();
    const [carregado, setCarregado] = useState(false);
    const { instance } = useMsal();

    const [alertType, setAlertType] = useState('success');
    const [alert, showAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertOptions, setAlertOptions] = useState([]);
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        if (user.credenciais) {
            setCarregado(true);
        }
    }, [user.credenciais]);

    const vincularLoginMicrosoft = async () => {
        try {
            const credenciaisAzureAd = await instance.loginPopup(loginRequest);
            const azureOid = credenciaisAzureAd.idTokenClaims.oid;
            const email = credenciaisAzureAd.idTokenClaims.preferred_username || credenciaisAzureAd.idTokenClaims.email;
            const cpf = await util.recuperarCpfAzure(credenciaisAzureAd.idTokenClaims.oid, credenciaisAzureAd.accessToken);
            //Verificar se o CPF da credencial é igual ao do usuário
            const cpfAzure = ('00000000000' + cpf.replace(/[\. ,:-]+/g, "")).slice(-11);
            if (!user.cpf || (('00000000000' + user.cpf.replace(/[\. ,:-]+/g, "")).slice(-11) !== cpfAzure)) {
                setAlertTitle(strings.atencao);
                showAlert(true);
                setAlertType('alert');
                setAlertMessage(strings.azureCpfIncompativelCadastro);
                setAlertOptions([{ title: strings.ok, onClick: () => showAlert(false) }]);
                return;
            }
            confirmarVincularContaMicrosoft(azureOid, email);
        } catch (error) {
            if (error instanceof ServerError || error instanceof ClientAuthError) {
                setAlertMessage(strings.erroGeralAutenticacaoExterna);
                setOnCloseAlerta(() => () => {
                    showAlert(false);
                })
                setAlertOptions([
                    {
                        title: strings.ok,
                        onClick: () => {
                            showAlert(false);
                        }
                    }
                ]);
                setAlertTitle(strings.erro);
                showAlert(true);
            } else if (error instanceof BrowserAuthError && error.errorCode === "interaction_in_progress") {
                setAlertMessage(strings.erroAutenticacaoExternaJaAberta);
                setOnCloseAlerta(() => () => {
                    showAlert(false);
                })
                setAlertOptions([
                    {
                        title: strings.ok,
                        onClick: () => {
                            showAlert(false);
                        }
                    }
                ]);
                setAlertTitle(strings.erro);
                showAlert(true);
            }
        }
    }

    const desvincularLoginMicrosoft = (autenticacao) => {
        setAlertType('alert');
        setAlertTitle(strings.atencao);

        if (autenticacao.obrigatoria) {
            setAlertMessage(strings.autenticacaoObrigatoria);
            setAlertOptions([{
                title: strings.ok, onClick: () => {
                    showAlert(false);
                }
            }]);
            showAlert(true);
            return;
        }

        setAlertMessage(strings.confirmarDesvincularAutenticacaoExterna(ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD));
        setAlertOptions([{
            title: strings.sim, onClick: () => {
                setCarregando(true);
                const payload = {
                    origem: autenticacao.origem,
                    id_usuario: autenticacao.id_usuario,
                    identificador_autenticacao: autenticacao.identificador_autenticacao,
                    login: user.login
                };
                const oauthBaseUrl = global.gConfig.url_base_api_oauth2;
                axios.post(`${oauthBaseUrl}/auth/desvincular-autenticacao-externa`, payload, { timeout: 15000 })
                    .then(function (response) {
                        Cache.setUserInfo(response.data);
                        setUser(response.data);
                        setAlertType('success');
                        setAlertTitle(strings.sucesso);
                        setAlertMessage(strings.sucessoDesvincularAutenticacaoExterna);
                        setAlertOptions([{ title: strings.ok, onClick: () => showAlert(false) }]);
                    })
                    .catch(function (error) {
                        console.log(error);

                        let msg = strings.erroGenerico;

                        const { response } = error;
                        if (response?.status < httpStatus.INTERNAL_SERVER_ERROR) {
                            msg = response.data.message;
                        }

                        setAlertType('error');
                        setAlertTitle(strings.erro);
                        setAlertMessage(msg);
                        showAlert(true);
                        setAlertOptions([{ title: strings.ok, onClick: () => showAlert(false) }]);
                    }).finally(() => {
                        setCarregando(false);
                    });
            }
        }, { title: strings.nao, onClick: () => showAlert(false) }]);
        showAlert(true);
    }

    const confirmarVincularContaMicrosoft = (azureOid, email) => {
        setAlertType('alert');
        setAlertTitle(strings.atencao);
        setAlertMessage(strings.confirmarVincularAutenticacaoExterna(ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD, email));
        setAlertOptions([{
            title: strings.sim, onClick: () => {
                setCarregando(true);
                const oauthBaseUrl = global.gConfig.url_base_api_oauth2;
                const payload = {
                    origem: ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD,
                    identificadorAutenticacao: azureOid,
                    idUsuario: user.id,
                    login: user.login,
                    email: email
                };

                axios.post(`${oauthBaseUrl}/auth/vincular-autenticacao-externa`, payload, { timeout: 15000 })
                    .then(function (response) {
                        Cache.setUserInfo(response.data);
                        setUser(response.data);
                        setAlertType('success');
                        setAlertTitle(strings.sucesso);
                        setAlertMessage(strings.sucessoVincularAutenticacaoExterna);
                        setAlertOptions([{ title: strings.ok, onClick: () => showAlert(false) }]);
                    })
                    .catch(function (error) {
                        console.log(error);

                        let msg = strings.erroGenerico;

                        const { response } = error;
                        if (response?.status < httpStatus.INTERNAL_SERVER_ERROR) {
                            msg = response.data.message;
                        }

                        setAlertType('error');
                        setAlertTitle(strings.erro);
                        setAlertMessage(msg);
                        showAlert(true);
                        setAlertOptions([{ title: strings.ok, onClick: () => showAlert(false) }]);
                    }).finally(() => {
                        setCarregando(false);
                    });
            }
        }, { title: strings.nao, onClick: () => showAlert(false) }]);
        showAlert(true);
    }

    // Cria o formulário de autenticação
    return (
        <>
            {
                carregado ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <SttPaper className={classes.formColumn}>
                            <SttBox className={classes.box} letterSpacing={1}>
                                <SttHeading variant="h2" color="primary" className={classes.heading}>
                                    {strings.meusDados}
                                </SttHeading>
                                <SttContainer>
                                    <SttExpansionPanel
                                        classegriditem={classes.expansionPanel}
                                        title={strings.dadosPessoais}
                                        children={
                                            <>
                                                <SttTextItem title={strings.nome} content={user.nome} />
                                                <SttTextItem title={strings.cpf} content={Util.util.adicionarMascara(`000${user.cpf}`, '000.000.000-00', { reverse: true })} />
                                                {
                                                    user.sexo &&
                                                    <SttTextItem title={strings.genero} content={user.sexo.descricao} />
                                                }
                                                {
                                                    user.cidade &&
                                                    <SttTextItem title={strings.cidadeUf} content={`${user.cidade.nome} / ${user.estado.sigla}`} />
                                                }
                                                {
                                                    user.numeroConselhoTrabalho && user.conselhoTrabalho && user.ufConselhoTrabalho &&
                                                    <SttTextItem title={strings.conselhoTrabalho} content={`${user.numeroConselhoTrabalho} ${user.conselhoTrabalho} / ${user.ufConselhoTrabalho}`} />
                                                }
                                                {
                                                    (user.cbo?.length > 0) &&
                                                    <SttTextItem title={strings.cboOcupacao} content={user.cbo[0].descricao_cbo_ocupacao} />
                                                }
                                            </>
                                        }
                                    />
                                    <Divider />
                                    {
                                        global.gConfig.autenticacao_externa?.ativo &&
                                        <>
                                            <SttExpansionPanel
                                                classegriditem={classes.expansionPanel}
                                                title={strings.conexoes}
                                                children={
                                                    <SttGrid container direction='column'>
                                                        <SttGrid item container align='center' xs={4} sm={2}>
                                                            {
                                                                global.gConfig.autenticacao_externa.origem.map(autenticacao => {
                                                                    if (!autenticacao.ativo) {
                                                                        return;
                                                                    }

                                                                    return (
                                                                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}>
                                                                            <SvgIcon className={classes.iconeAutenticacaoExterna}>
                                                                                {
                                                                                    UtilLocal.retornarIconeAutenticacaoExterna(autenticacao.identificador)
                                                                                }
                                                                            </SvgIcon>

                                                                            {
                                                                                user.autenticacaoExterna?.some(autenticacaoUsuario => autenticacaoUsuario.origem === autenticacao.identificador) ?
                                                                                    <SttLink
                                                                                        onClick={() => desvincularLoginMicrosoft({ ...autenticacao, ...user.autenticacaoExterna.filter(autenticacaoUsuario => autenticacaoUsuario.origem === autenticacao.identificador)[0] })}
                                                                                        target={`_blank`}
                                                                                        style={{
                                                                                            cursor: 'pointer', color: 'red', fontFamily: [
                                                                                                'Inter',
                                                                                                '-apple-system',
                                                                                                'Roboto',
                                                                                                '"Segoe UI"',
                                                                                                '"Helvetica Neue"',
                                                                                                'Arial',
                                                                                                'sans-serif'
                                                                                            ].join(',')
                                                                                        }}
                                                                                    >
                                                                                        {strings.desvincular(autenticacao.identificador)}
                                                                                    </SttLink> :
                                                                                    <SttLink
                                                                                        onClick={vincularLoginMicrosoft}
                                                                                        style={{
                                                                                            cursor: 'pointer', fontFamily: [
                                                                                                'Inter',
                                                                                                '-apple-system',
                                                                                                'Roboto',
                                                                                                '"Segoe UI"',
                                                                                                '"Helvetica Neue"',
                                                                                                'Arial',
                                                                                                'sans-serif'
                                                                                            ].join(',')
                                                                                        }}
                                                                                        target={`_blank`}
                                                                                    >
                                                                                        {strings.vincular(autenticacao.identificador)}
                                                                                    </SttLink>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </SttGrid>
                                                        {
                                                            (!global.gConfig.autenticacao_externa.origem.length ||
                                                                global.gConfig.autenticacao_externa.origem.every(origem => !origem.ativo)) &&
                                                            <SttNotification severity="info" className={classes.notificacao}>
                                                                {strings.semConexoes}
                                                            </SttNotification>
                                                        }
                                                    </SttGrid>
                                                }
                                            />
                                            <Divider />
                                        </>
                                    }

                                    <SttExpansionPanel
                                        classegriditem={classes.expansionPanel}
                                        title={strings.vinculos}
                                        children={
                                            <>
                                                {
                                                    user.habilitacao.vinculo &&
                                                    <>
                                                        <Instituicao instituicoes={user.habilitacao.vinculo} />
                                                        {
                                                            global.gConfig.secao_equipe_saude_cadastro_funcionario &&
                                                            <>
                                                                <Divider />
                                                                <Equipe instituicoes={user.habilitacao.vinculo} />
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    />
                                    <Divider />
                                    <SttExpansionPanel
                                        classegriditem={classes.expansionPanel}
                                        title={strings.servicos}
                                        children={
                                            <>
                                                {
                                                    user.habilitacao.solicitacaoServico &&
                                                    <>
                                                        <Instituicao instituicoes={user.habilitacao.solicitacaoServico} />

                                                        <Divider />
                                                        <Setor instituicoes={user.habilitacao.solicitacaoServico} />

                                                        <Divider />
                                                        <Modalidade instituicoes={user.habilitacao.solicitacaoServico} redes={user.habilitacao.redes} />
                                                    </>
                                                }
                                            </>
                                        }
                                    />
                                    <Divider />
                                    <SttExpansionPanel
                                        classegriditem={classes.expansionPanel}
                                        title={strings.usuario}
                                        children={
                                            <>
                                                <SttTextItem title={strings.login} content={user.login} />
                                                <SttTextItem title={strings.email} content={user.email} />
                                                <Form user={user} />
                                            </>
                                        }
                                    />
                                    <Divider />
                                </SttContainer>
                            </SttBox>
                        </SttPaper>
                    </div>
                    :
                    <SttLoading
                        open={!carregado}
                        text={strings.carregando}
                    />
            }

            <SttAlerta
                open={alert}
                title={alertTitle}
                message={alertMessage}
                type={alertType}
                options={alertOptions}
                onClose={() => {
                    showAlert(false);
                }}
            />

            <SttLoading
                open={carregando}
                text={strings.carregando}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUserAction(user))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('MeusDados')(withRouter(MeusDados)));
