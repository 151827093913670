import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter, useLocation } from 'react-router-dom';
import {
    MateriaisSuporte,
    SttHeaderV2,
    SttHelpdeskShortcut,
    SttMainNavigationBarV2,
    SttSplashScreen
} from '@stt-componentes/core';
import translate from './../translate';
import { getBearerHeaders } from '../requests';

const Menu = ({ strings, user }) => {
    const location = useLocation();
    const history = useHistory();

    const [tabAtual, setTabAtual] = useState(0);
    const [submenus, setSubmenus] = useState([]);
    const [openSS, setOpenSS] = useState(false);
    const [openMS, setOpenMS] = useState(false);

    useEffect(() => {
        let menuOptions = [];
        if (user.credenciais) {
            menuOptions = [
                {
                    id: '/inicio',
                    text: strings.inicio,
                    onClick: () => {
                        if (history.location.pathname !== '/inicio') {
                            history.push(`/inicio`);
                        }
                    }
                },
                {
                    id: '/meus-dados',
                    text: strings.meusDados,
                    onClick: () => {
                        if (history.location.pathname !== '/meus-dados') {
                            history.push(`/meus-dados`);
                        }
                    }
                }
            ];

            if (global.gConfig.exibir_link_esqueceu_senha) {
                menuOptions = [
                    ...menuOptions,
                    {
                        id: '/alteracao-senha',
                        text: strings.alteracaoSenha,
                        onClick: () => {
                            if (
                                history.location.pathname !== '/alteracao-senha'
                            ) {
                                history.push(`/alteracao-senha`);
                            }
                        }
                    }
                ];
            }

            menuOptions = [
                ...menuOptions,
                {
                    id: '/alteracao-foto',
                    text: strings.alteracaoFoto,
                    onClick: () => {
                        if (history.location.pathname !== '/alteracao-foto') {
                            history.push(`/alteracao-foto`);
                        }
                    }
                },
                // {
                //     id: '/log-senha',
                //     text: strings.logDeSenha,
                //     onClick: () => {
                //         if (history.location.pathname !== '/log-senha') {
                //             history.push(`/log-senha`);
                //         }
                //     }
                // }
            ];
            setSubmenus(menuOptions);
        }

        submenus.forEach((menu, i) => {
            if (menu.id === location.pathname) {
                setTabAtual(i);
            }
        });
    }, [history, location.pathname, user, user.credenciais]);

    useEffect(() => {
        submenus.forEach((menu, i) => {
            if (menu.id === location.pathname) {
                setTabAtual(i);
            }
        });
    }, [location.pathname, submenus]);

    return (
        <div>
            <SttHeaderV2
                nomeUsuario={user?.nome || ''}
                config={global.gConfig}
                strings={strings}
                headers={getBearerHeaders()}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => setOpenSS(!openSS),
                    onClickMateriaisSuporte: () => setOpenMS(!openMS),
                }}
                submenus={submenus}
                selectedTab={tabAtual}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                open={openSS}
                setOpen={setOpenSS}
            />
            <SttHelpdeskShortcut config={global.gConfig} usuario={user} />
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getBearerHeaders()}
                open={openMS}
                setOpen={setOpenMS}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps)(
    translate('Cabecalho')(withRouter(Menu))
);
