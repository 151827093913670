import React, { useEffect } from 'react';
import { MODULOS_CONSTANTES } from '../common/AppConstants';
import { Cache } from '@stt-componentes/cache';

const MenuModulosMobile = () => {

    useEffect(() => {
        async function fetchModules() {
            const userInfo = await Cache.getUserInfoAsync(global.gConfig);
            var modulos = [];

            if (userInfo?.modulos) {
                userInfo.modulos.forEach(modulo => {
                    if (MODULOS_CONSTANTES[modulo] && global.gConfig.urls_modulos[modulo]) {
                        let moduloFormatado = {
                            url: global.gConfig.urls_modulos[modulo],
                            nome: MODULOS_CONSTANTES[modulo],
                            modulo
                        };
                        modulos.push(moduloFormatado);
                    }
                });
                modulos.sort((a, b) => a.nome.localeCompare(b.nome));

                const message = {
                    "acao": "MENU_MODULOS",
                    "data": {
                        modulos: JSON.stringify(modulos)
                    },
                };
                window.parent.postMessage(message, "*");
            }
        }

        fetchModules();
    }, []);

    return (
        <></>
    );
};

export default MenuModulosMobile;
